import Colocation from "@/pages/Colocation/Colocation.vue";
import ColocationDetails from "@/pages/Colocation/ColocationDetails.vue";
import GenericIncidentForm from "@/pages/Incident/GenericIncidentForm.vue";
import GenericChangeRequestForm from "@/pages/ChangeRequest/GenericChangeRequestForm.vue";
import ChangeRequestForm from "@/pages/ChangeRequest/ChangeRequestForm.vue";
import IncidentForm from "@/pages/Incident/IncidentForm.vue";
import IncidentDetails from "@/pages/Incident/IncidentDetails.vue";
import HelpRequestDetails from "@/pages/Help/HelpRequestDetails.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";

export function routesColocation() {
  return [
    {
      path: "/colocation", // Non va bene ma almeno non si rompe la modale incident
      name: "Colocationit",
      component: Colocation,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["colocation"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/colocation", // Non va bene ma almeno non si rompe la modale incident
      name: "Colocationen",
      component: Colocation,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["colocation"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/colocation/:id",
      name: "ColocationDetailt",
      component: ColocationDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "/colocation",
          },
          {
            name: "colocation_detail",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["colocation"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/incident-generico/:asset_type",
      name: "GenericIncidentFormColocationit",
      component: GenericIncidentForm,
      meta: {
        team_link: "incident_Colocation",
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "/colocation",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/generic-incident/:asset_type",
      name: "GenericIncidentFormColocationen",
      component: GenericIncidentForm,
      meta: {
        team_link: "incident_Colocation",
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "/colocation",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/change-request-generica/:asset_type",
      name: "GenericChangeRequestFormColocationit",
      component: GenericChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "/colocation",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/generic-change-request/:asset_type",
      name: "GenericChangeRequestFormColocationen",
      component: GenericChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "/colocation",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/incident/:asset_id/:asset_type",
      name: "IncidentFormColocationit",
      component: IncidentForm,
      meta: {
        team_link: "incident_Colocation",
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "/colocation",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/incident/:asset_id/:asset_type",
      name: "IncidentFormColocationen",
      component: IncidentForm,
      meta: {
        team_link: "incident_Colocation",
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "/colocation",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/change-request/:asset_id/:asset_type",
      name: "ChangeRequestFormColocationit",
      component: ChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "/colocation",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/change-request/:asset_id/:asset_type",
      name: "ChangeRequestFormColocationit",
      component: ChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "/colocation",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/change-request/:asset_id/:asset_type",
      name: "ChangeRequestFormColocationen",
      component: ChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "/colocation",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/colocation/lista-incident/dettaglio/:id",
      name: "IncidentDetailColocationit",
      component: IncidentDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "/colocation",
          },
          {
            name: "incident",
            url: "",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/colocation/incident-list/detail/:id",
      name: "IncidentDetailColocationen",
      component: IncidentDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "/colocation",
          },
          {
            name: "incident",
            url: "",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/colocation/lista-change-request/dettaglio/:id",
      name: "ChangeRequestDetailColocationit",
      component: HelpRequestDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "/colocation",
          },
          {
            name: "changeRequest",
            url: "",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/colocation/change-request-list/detail/:id",
      name: "ChangeRequestDetailColocationen",
      component: HelpRequestDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "colocation",
            url: "/colocation",
          },
          {
            name: "changeRequest",
            url: "",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "colocation",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ];
}
