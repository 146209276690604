<template>
  <div class="solutions-ticket-list">
    <table>
      <thead v-if="!IsMobile">
        <tr>
          <th v-if="!IsMobile">{{ $t("ticketsList.default.created_at") }}</th>
          <th v-if="!IsMobile">{{ $t("ticketsList.default.title") }}</th>
          <th>{{ $t("ticketsList.default.request") }}</th>
          <th>{{ $t("ticketsList.default.solution") }}</th>
          <th v-if="!IsMobile">{{ $t("ticketsList.default.reference") }}</th>
          <th v-if="!IsMobile">{{ $t("ticketsList.default.status") }}</th>

          <th v-if="!IsMobile" class="refreshList">
            <span
              class="material-icons"
              @click="getTickets()"
              v-tooltip:top.tooltip="$t('ticketsList.tooltip')"
              >autorenew</span
            >
          </th>
        </tr>
      </thead>
      <div v-if="IsMobile && this.viewLoader && this.list.length">
        <MobileCard
          v-for="(item, keyItem) in this.list"
          :key="keyItem"
          :titles="['cf_servizi', 'cf_sold_solution']"
          :item="item"
          translationPath="ticketsList.ActiveLines"
        >
          <template #url-label>
            <span
              @click="goToPage(componentNameIncident, item.ticketNumber)"
              v-if="item.cf_servizi === 'Incident'"
            >
              <div class="cdlButton">
                {{ $t("tickets.details") }}
              </div>
            </span>
            <span
              @click="goToPage(componentNameChangeRequest, item.ticketNumber)"
              v-if="item.cf_servizi === 'ChangeRequest'"
            >
              <div class="cdlButton">
                {{ $t("tickets.details") }}
              </div>
            </span>
            <router-link
              v-if="item.cf_servizi === 'AssistenzaTecnica'"
              :to="`${
                '/lista-richieste/' + $t('nav.detail') + '/' + item.ticketNumber
              }`"
            >
              <div class="cdlButton">
                {{ $t("tickets.details") }}
              </div>
            </router-link></template
          >
        </MobileCard>
      </div>
      <tbody v-else-if="!IsMobile && this.viewLoader && this.list.length">
        <tr
          v-for="(item, keyItem) in this.list"
          :key="keyItem"
          :class="{ odd: keyItem % 2 }"
        >
          <td>
            <span>{{ this.getDate(item.createdTime) }}</span>
          </td>
          <td>
            <span>{{ item.cf_short_title }}</span>
          </td>
          <td>
            <span>{{ item.cf_servizi }}</span>
          </td>
          <td>
            <span>{{ item.cf_sold_solution }}</span>
          </td>
          <td>
            <span>{{ item.cf_email_referente }}</span>
          </td>
          <td>
            <span
              :class="`statusTickets status${getClassStatus(item.statusType)}`"
            >
              {{ $t("tickets." + getClassStatus(item.statusType)) }}</span
            >
          </td>

          <td>
            <div style="width: 150px; margin: 0 auto">
              <span
                @click="goToPage(componentNameIncident, item.ticketNumber)"
                v-if="item.cf_servizi === 'Incident'"
              >
                <div class="cdlButton">{{ $t("tickets.details") }}</div>
              </span>
              <span
                @click="goToPage(componentNameChangeRequest, item.ticketNumber)"
                v-if="item.cf_servizi === 'ChangeRequest'"
              >
                <div class="cdlButton">{{ $t("tickets.details") }}</div>
              </span>
              <router-link
                v-if="item.cf_servizi === 'AssistenzaTecnica'"
                :to="`${
                  '/lista-richieste/' +
                  $t('nav.detail') +
                  '/' +
                  item.ticketNumber
                }`"
              >
                <div class="cdlButton">{{ $t("tickets.details") }}</div>
              </router-link>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="this.viewLoader">
        <tr>
          <td colspan="7" style="text-align: center">
            <span>{{ $t("tickets.noTickets") }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <br /><br />

      <MyError :viewError="viewError" :message="$t('tickets.error')" />
    </div>
    <paginator
      v-if="totalPage > 1"
      :total-page="totalPage"
      :current-page="currentPage"
      @change-page="this.changePage"
      @prev-page="this.prevPage"
      @next-page="this.nextPage"
    ></paginator>
  </div>
</template>

<script>
import MyError from "@/components/MyError.vue";
import Paginator from "@/components/Paginator.vue";

import moment from "moment";
import MobileCard from "@/components/Cards/MobileCard.vue";
import ticketList from "@/components/CrossConnect/TicketList.vue";

export default {
  name: "ColocationTicketList",
  components: {
    MobileCard,
    MyError,
    Paginator,
  },
  props: {
    list: Array,
    totalCount: Number,
    viewLoader: Boolean,
    viewError: Boolean,
    componentNameIncident: String,
    componentNameChangeRequest: String,
  },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      messageError: "",
      IsMobile: this.$IsMobile(),
    };
  },
  methods: {
    getClassStatus(string) {
      return typeof string == "string" ? string.replace(" ", "") : "";
    },
    getDate(myTime) {
      return moment(myTime).format("DD-MM-YYYY", this.$i18n.locale, true);
    },
    changePage(page) {
      this.currentPage = page;
      this.$emit("change-page", page);
    },
    nextPage() {
      if (this.currentPage == this.totalPage) {
        return;
      }
      this.currentPage = parseInt(this.currentPage) + 1;
      this.$emit("change-page", this.currentPage);
    },
    prevPage() {
      if (this.currentPage == 1) {
        return;
      }
      this.currentPage = parseInt(this.currentPage) - 1;
      this.$emit("change-page", this.currentPage);
    },
    getTickets() {
      this.$emit("refresh");
    },
    goToPage(component, id) {
      return this.$router.push({
        name: component + this.$i18n.locale,
        params: { id: id },
      });
    },
  },
  computed: {
    ticketList() {
      return ticketList;
    },
    totalPage() {
      return Math.ceil(this.totalCount / this.pageSize) > 1
        ? Math.ceil(this.totalCount / this.pageSize)
        : 1;
    },
  },
};
</script>
