<template>
  <div id="Shipping">
    <MyBanner
      :text="$t('banner.accessDcPage')"
      v-if="$t('banner.accessDcPage') != ''"
    />
    <MyHeaderPage
      :title="$t('accessDc.title')"
      :subtitle="$t('accessDc.subTitle')"
      button="adc"
    />
    <br /><br />

    <!-- -->
    <div id="Users" class="AccessDCList">
      <div class="mdl-tabs mdl-js-tabs mdl-js-ripple-effect">
        <div
          class="mdl-tabs__tab-bar contentTabs"
          :class="{ opacity: allUsersBio.length == 0 }"
        >
          <span
            class="mdl-tabs__tab tabOne is-active"
            id="tabOne"
            @click="setTab(1)"
            >{{ $t("accessDc.ticketList") }}
            <div class="line"></div>
          </span>
          <span
            class="mdl-tabs__tab tabTwo"
            id="tabTwo"
            @click="setTab(2)"
            v-if="allUsersBio.length > 0"
            >{{ $t("accessDc.accessDcList") }}
            <div class="line"></div>
          </span>
        </div>

        <div
          class="mdl-tabs__panel tabOne is-active"
          :class="{ noBorder: allUsersBio.length == 0 }"
          :style="{ padding: IsMobile ? '0px' : '20px' }"
        >
          <div class="mdl-grid" :style="{ padding: IsMobile ? '0px' : '8px' }">
            <div
              class="mdl-cell mdl-cell--12-col"
              :style="{ padding: IsMobile ? '0px' : '8px' }"
            >
              <TicketsList ticket-type="Ingresso" details-url="accessDcUrl" />
            </div>
          </div>
        </div>
        <div
          class="mdl-tabs__panel tabTwo"
          id="TicketsList"
          :style="{ padding: IsMobile ? '0px' : '20px' }"
        >
          <div
            class="mdl-grid listTicket"
            :style="{ padding: IsMobile ? '0px' : '8px' }"
          >
            <!-- FILTER -->
            <div class="mdl-cell mdl-cell--5-col"></div>
            <div class="mdl-cell mdl-cell--5-col" v-if="false">
              <div class="filterTickets">
                <div class="contentInputFilter">
                  <div class="contentIcon">
                    <span class="material-icons">search</span>
                  </div>
                  <div class="contentInput">
                    <input
                      type="text"
                      :placeholder="$t('tickets.search')"
                      v-model="searchString"
                      @keyup="searchByString"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--1-col" v-if="!IsMobile"></div>
            <div class="mdl-cell mdl-cell--3-col mdl-cell--12-col-phone">
              <div class="contentInputFilter" style="margin-bottom: 0">
                <div class="multiselectFilter">
                  {{ $t("tickets.statusType") }}:
                  <strong>
                    <span
                      :style="
                        multiSelected.state.string.length > 10
                          ? { fontSize: '16px' }
                          : { fontSize: '16px' }
                      "
                      v-if="typeof multiSelected.state.string == 'string'"
                    >
                      {{ $t(multiSelected.state.string) }}
                    </span>
                    <span
                      :style="
                        multiSelected.state.string.length > 10
                          ? { fontSize: '16px' }
                          : { fontSize: '16px' }
                      "
                      v-else
                    >
                      <span
                        v-for="(value, index) in multiSelected.state.string"
                        :key="index"
                      >
                        <span v-if="index == 0">
                          {{
                            $t("tickets." + value.toString().replace(" ", ""))
                          }}
                          <span
                            v-if="
                              index != multiSelected.state.string.length - 1
                            "
                            >,&nbsp;</span
                          >
                        </span>
                        <span v-else>...</span>
                      </span>
                    </span>
                  </strong>
                </div>
                <div
                  class="contentIcon cursorPointer"
                  @click="openMultiselect('state')"
                >
                  <span class="material-icons" v-if="multiSelected.state.onOff"
                    >expand_less</span
                  >
                  <span class="material-icons" v-if="!multiSelected.state.onOff"
                    >expand_more</span
                  >
                </div>
              </div>
              <div class="listMultiselect" v-if="multiSelected.state.onOff">
                <div>
                  <input
                    type="checkbox"
                    class="stateAll"
                    value="-1"
                    @click="searchByParams('Tutto', $event, 'state')"
                  />
                  {{ $t("checkCoverage.filter.all") }}
                </div>

                <div
                  v-for="(value, index) in multiSelected.state.items"
                  :key="index"
                >
                  <input
                    type="checkbox"
                    class="state"
                    :value="value"
                    @click="searchByParams(value, $event, 'state')"
                  />
                  {{ $t("tickets." + getClassStatus(value)) }}
                </div>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--3-col mdl-cell--12-col-phone">
              <div class="contentInputFilter" style="margin-bottom: 0">
                <div
                  class="multiselectFilter"
                  :style="
                    multiSelected.period.string != 'Tutto'
                      ? { fontSize: '16px' }
                      : { fontSize: '16px' }
                  "
                >
                  {{ $t("tickets.when") }}:
                  <strong
                    ><span>{{ multiSelected.period.string }}</span></strong
                  >
                </div>
                <div
                  class="contentIcon cursorPointer"
                  @click="openMultiselect('period')"
                >
                  <span class="material-icons" v-if="multiSelected.period.onOff"
                    >expand_less</span
                  >
                  <span
                    class="material-icons"
                    v-if="!multiSelected.period.onOff"
                    >expand_more</span
                  >
                </div>
              </div>
              <div class="listMultiselect" v-if="multiSelected.period.onOff">
                <div>
                  <input
                    type="radio"
                    class="periodAll"
                    name="period"
                    value="-1"
                    id="period--1"
                    @click="searchByPeriod(-1, $t('checkCoverage.filter.all'))"
                  />
                  {{ $t("checkCoverage.filter.all") }}
                </div>
                <div>
                  <input
                    type="radio"
                    class="period"
                    value="14"
                    name="period"
                    id="period-14"
                    @click="
                      searchByPeriod(14, $t('tickets.lastDays', { days: 14 }))
                    "
                  />
                  {{ $t("tickets.lastDays", { days: 14 }) }}
                </div>
                <div>
                  <input
                    type="radio"
                    class="period"
                    value="30"
                    name="period"
                    id="period-30"
                    @click="
                      searchByPeriod(30, $t('tickets.lastDays', { days: 30 }))
                    "
                  />
                  {{ $t("tickets.lastDays", { days: 30 }) }}
                </div>
                <div>
                  <input
                    type="radio"
                    class="period"
                    value="90"
                    name="period"
                    id="period-90"
                    @click="
                      searchByPeriod(90, $t('tickets.lastDays', { days: 90 }))
                    "
                  />
                  {{ $t("tickets.lastDays", { days: 90 }) }}
                </div>
              </div>
            </div>
            <!-- FINE FILTER -->
            <div
              class="mdl-cell mdl-cell--12-col"
              :style="{ padding: IsMobile ? '0px' : '8px' }"
            >
              <br />
              <table v-if="items.length > 0">
                <thead v-if="!IsMobile">
                  <tr>
                    <th v-for="(header, keyHead) in columns" :key="keyHead">
                      {{ $t("ticketsList.Ingresso." + header) }}
                    </th>
                    <th>Utenti</th>
                    <th></th>
                  </tr>
                </thead>
                <thead v-if="IsMobile">
                  <tr>
                    <th>
                      {{ $t("ticketsList.Ingresso.cf_from") }}
                    </th>
                    <!-- <th>
                      {{ $t("ticketsList.Ingresso.cf_to") }}
                    </th> -->
                  </tr>
                </thead>
                <tbody v-if="!IsMobile && this.viewLoader">
                  <tr
                    v-for="(item, keyItem) in this.items"
                    :key="keyItem"
                    :class="{ odd: keyItem % 2 }"
                  >
                    <td
                      v-for="(index, keyIndex) in columns.length"
                      :key="keyIndex"
                    >
                      <span
                        v-if="this.columns[keyIndex] == 'statusType'"
                        class="statusTickets"
                        :class="[
                          'status' +
                            getClassStatus(
                              item[this.columns[keyIndex]].toString()
                            ),
                        ]"
                      >
                        {{
                          $t(
                            "tickets." +
                              getClassStatus(
                                item[this.columns[keyIndex]].toString()
                              )
                          )
                        }}
                      </span>
                      <span v-else-if="this.columns[keyIndex] == 'createdTime'">
                        {{ getDate(item[this.columns[keyIndex]]) }}
                      </span>
                      <span
                        v-else-if="
                          this.columns[keyIndex] == 'cf_from' ||
                          this.columns[keyIndex] == 'cf_to'
                        "
                      >
                        {{ getDateTime(item[this.columns[keyIndex]]) }}
                      </span>
                      <span v-else>{{ item[this.columns[keyIndex]] }}</span>
                    </td>
                    <td v-if="item.users[0]">
                      <span v-for="(user, key) in item.users" :key="key"
                        ><span v-if="user"
                          >{{ user.surname }} {{ user.name }}</span
                        ></span
                      >
                    </td>
                    <td v-else>
                      <span>{{ $t("tickets.noUserBio") }}</span>
                    </td>
                    <td>
                      <div class="cdlButton" @click="setInfo(item)">
                        {{ $t("tickets.details") }}
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="IsMobile && this.viewLoader">
                  <MobileCard
                    class="philange"
                    v-for="(item, keyItem) in this.items"
                    :key="keyItem"
                    :item="item"
                    translation-path="ticketsList.Ingresso"
                    :titles="['cf_from', 'cf_to']"
                  >
                    <template #url-label>
                      <div class="cdlButton" @click="setInfo(item)">
                        {{ $t("tickets.details") }}
                      </div></template
                    >
                  </MobileCard>
                </tbody>
              </table>
              <div class="noTickets" v-if="items.length == 0 && viewLoader">
                {{ $t("tickets.noBookeds") }}
              </div>
              <div>
                <MyLoading :viewLoader="this.viewLoader" type="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- -->
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import TicketsList from "@/components/TicketsList.vue";
import "@/assets/scss/Users.scss";
import moment from "moment";
import { useAccessDcStore } from "@/stores/AccessDcStore";
import MyLoading from "@/components/MyLoading.vue";

import MobileCard from "@/components/Cards/MobileCard.vue";

export default {
  name: "AccessRequestList",
  components: {
    MobileCard,
    MyBanner,
    MyHeaderPage,
    TicketsList,
    MyLoading,
  },
  data() {
    return {
      columns: ["createdTime", "cf_from", "cf_to", "statusType"],
      items: [],
      searchString: "",
      filterDate: "",
      multiSelected: {
        state: {
          onOff: false,
          string: "Tutto",
          values: [],
          items: ["1", "0"],
        },
        period: {
          onOff: false,
          string: "Tutto",
          values: [],
          items: [],
        },
      },
      IsMobile: this.$IsMobile(),
      viewLoader: false,
      isBio: false,
      allUsersBio: [],
    };
  },
  methods: {
    setTab(tab) {
      if (tab == 1) {
        document
          .getElementsByClassName("tabTwo")[0]
          .classList.remove("is-active");
        if (document.getElementsByClassName("tabTwo")[1]) {
          document
            .getElementsByClassName("tabTwo")[1]
            .classList.remove("is-active");
        }
        document.getElementsByClassName("tabOne")[1].classList.add("is-active");

        document.getElementsByClassName("tabOne")[0].classList.add("is-active");
      } else {
        if (document.getElementsByClassName("tabTwo")[1]) {
          document
            .getElementsByClassName("tabTwo")[1]
            .classList.add("is-active");
        }
        document
          .getElementsByClassName("tabOne")[1]
          .classList.remove("is-active");

        document.getElementsByClassName("tabTwo")[0].classList.add("is-active");
        document
          .getElementsByClassName("tabOne")[0]
          .classList.remove("is-active");
      }
    },

    searchByString() {
      if (this.searchString.length >= 3 || this.searchString.length == 0) {
        this.getBookeds();
      }
    },
    searchByParams(params, ev, classSel) {
      let pointer = this.multiSelected.state;

      (function (x, params, ev) {
        if (params == "Tutto") {
          x.values = [];
          x.string = "Tutto";
          let checkBox = document.getElementsByClassName(classSel);
          for (let i = 0; i < checkBox.length; i++) {
            checkBox[i].checked = false;
          }
        } else {
          let classAll = classSel + "All";
          document.getElementsByClassName(classAll)[0].checked = false;
          if (ev.target.checked) {
            x.values.push(params);
          } else {
            let index = x.values.indexOf(params);
            if (index !== -1) {
              x.values.splice(index, 1);
            }
          }

          x.string = x.values;
        }
      })(pointer, params, ev);

      if (pointer.values.length == 0) {
        pointer.string = "Tutto";
        let checkBox = document.getElementsByClassName(classSel);
        for (let i = 0; i < checkBox.length; i++) {
          checkBox[i].checked = false;
        }
        document.getElementsByClassName(classSel + "All")[0].checked = true;
      }

      pointer.onOff = false;

      this.getBookeds();
    },
    searchByPeriod(params, label) {
      if (params == -1) {
        this.filterDate = "";
      } else {
        // yyyy-MM-ddThh:mm:ss.SSSZ
        let dateTo = moment().format("DD-MM-YYYY");
        let dateFrom = moment().subtract(params, "d").format("DD-MM-YYYY");

        this.filterDate = dateFrom + "," + dateTo;
      }

      this.multiSelected.period.string = label;

      this.multiSelected.period.values.push(params);

      this.multiSelected.period.onOff = false;

      document.getElementById("period-" + params).checked = true;

      this.getBookeds();
    },
    openMultiselect(type) {
      let ctrl = true;
      let selected = [];
      let pointer = "";
      switch (type) {
        case "state":
          pointer = this.multiSelected.state;
          break;
        case "period":
          pointer = this.multiSelected.period;
          break;
        // case 'user_refs':
        //   pointer = this.multiSelected.user_refs;
        //   break;
      }

      (function (x) {
        x.onOff = !x.onOff;
        ctrl = x.onOff;
        selected = x.values;
      })(pointer);

      if (ctrl && selected.length > 0) {
        let checkBox = document.getElementsByClassName(type);
        setTimeout(() => {
          for (let i = 0; i < checkBox.length; i++) {
            checkBox[i].checked = false;
            selected.forEach((elm) => {
              if (checkBox[i].value == elm) {
                checkBox[i].checked = true;
              }
            });
          }
        }, 100);
      } else if (ctrl && selected.length == 0) {
        let classAll = type + "All";
        setTimeout(() => {
          document.getElementsByClassName(classAll)[0].checked = true;
        }, 100);
      }
    },
    getDate(myTime) {
      return moment(myTime).format("DD-MM-YYYY", this.$i18n.locale, true);
    },
    getDateTime(myTime) {
      return moment(myTime).format("DD-MM-YYYY HH:mm", this.$i18n.locale, true);
    },
    getClassStatus(string) {
      return typeof string == "string" ? string.replace(" ", "") : "";
    },
    setInfo(item) {
      let accessDc = useAccessDcStore();

      let typeAccess = ["coworking"];
      if (item.cars.length > 0) {
        typeAccess.push("parking");
      }

      accessDc.form = {
        dc: {
          dc_id: 7,
          dc_name: "Data Center C21 - Milano",
          address: "Via Caldera,21,20053,Milano,Italia",
          fromTime: null,
          toTime: null,
          apparati: "no",
          manutenzione: false,
          keys: [],
          noteKey: null,
          racks: [],
          users: [],
          mop: null,
          ref: {
            ref_name: null,
            ref_surname: null,
            ref_phone: null,
            ref_email: null,
          },
        },
        coworking: {
          desks: item.users.length,
          fromTime: item.cf_from.split(" ")[1],
          toTime: item.cf_to.split(" ")[1],
          users: item.users,
        },
        parking: {
          fromTime: item.cf_from.split(" ")[1],
          toTime: item.cf_to.split(" ")[1],
          users: item.users,
          cars: item.cars,
        },
        typeAccess: typeAccess,
        dateAccess: item.cf_from.split(" ")[0],
      };

      accessDc.lang = null;
      accessDc.isCompleted = true;
      accessDc.gammaId = accessDc.getGammaId();
      accessDc.usersBio = item.users;
      accessDc.onlyRead = true;

      console.log(item);

      accessDc.generateSteps(item.cars.length);

      return this.$router.push({
        name: "AccessRequestSummary" + this.$i18n.locale,
      });
    },
    getBookeds() {
      this.viewLoader = false;

      let params = {};

      if (this.searchString != "") {
        params.search_by = this.searchString;
      }

      if (this.multiSelected.state.values.length > 0) {
        params.status = this.multiSelected.state.values.join(",");
      } else {
        params.status = "0,1";
      }

      if (this.filterDate != "") {
        params.createdTimeRange = this.filterDate;
      }

      const self = this;

      this.items = [];

      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };
      this.axios
        .post(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "front/v1/list-booked",
          params,
          { headers: he }
        )
        .then((response) => {
          this.viewLoader = true;
          if (response.data.status == "ok") {
            this.items = response.data.data;
          }
        })
        .catch(function (err) {
          console.log(err);
          if (err.request.status == 404) {
            self.viewLoader = true;
            return;
          }
          self.viewLoader = true;
          self.viewError = true; //err.message; //"Nessuna copertura";
          self.messageError = err.message;
          setTimeout(() => {
            self.viewError = false;
          }, 5000);
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$IsLoggedIn();
    this.setTab(1);
    this.getBookeds();

    let accessDc = useAccessDcStore();
    let timer = 0;
    if (!accessDc.isBio) {
      timer = 6000;
    }
    setTimeout(() => {
      this.isBio = accessDc.isBio;
      this.allUsersBio = accessDc.usersBio;
    }, timer);
  },
};
</script>
