<template>
  <div id="TicketsList">
    <div class="listTicket" :class="[ticketType]">
      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--4-col mdl-cell mdl-cell--12-col-phone">
          <div class="filterTickets">
            <div class="contentInputFilter">
              <div class="contentIcon">
                <span class="material-icons">search</span>
              </div>
              <div class="contentInput">
                <input
                  type="text"
                  :placeholder="$t('actions.text_search_placeholder')"
                  v-model="searchString"
                  @keyup="searchByString"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          :class="[
            IsMobile
              ? 'mdl-cell mdl-cell--12-col-phone'
              : 'mdl-cell mdl-cell--3-col mdl-cell--2-offset',
          ]"
        >
          <div class="contentInputFilter" style="margin-bottom: 0">
            <div class="multiselectFilter">
              {{ $t("tickets.statusType") }}:
              <strong>
                <span
                  :style="
                    multiSelected.state.string.length > 10
                      ? { fontSize: '16px' }
                      : { fontSize: '16px' }
                  "
                  v-if="typeof multiSelected.state.string == 'string'"
                >
                  {{ $t(multiSelected.state.string) }}
                </span>
                <span
                  :style="
                    multiSelected.state.string.length > 10
                      ? { fontSize: '16px' }
                      : { fontSize: '16px' }
                  "
                  v-else
                >
                  <span
                    v-for="(value, index) in multiSelected.state.string"
                    :key="index"
                  >
                    <span v-if="index == 0">
                      {{ $t("tickets." + value.toString().replace(" ", "")) }}
                      <span
                        v-if="index != multiSelected.state.string.length - 1"
                        >,&nbsp;</span
                      >
                    </span>
                    <span v-else>...</span>
                  </span>
                </span>
              </strong>
            </div>
            <div
              class="contentIcon cursorPointer"
              @click="openMultiselect('state')"
            >
              <span class="material-icons" v-if="multiSelected.state.onOff"
                >expand_less</span
              >
              <span class="material-icons" v-if="!multiSelected.state.onOff"
                >expand_more</span
              >
            </div>
          </div>
          <div class="listMultiselect" v-if="multiSelected.state.onOff">
            <div>
              <input
                type="checkbox"
                class="stateAll"
                value="-1"
                @click="searchByParams('Tutto', $event, 'state')"
              />
              {{ $t("checkCoverage.filter.all") }}
            </div>

            <div
              v-for="(value, index) in multiSelected.state.items"
              :key="index"
            >
              <input
                type="checkbox"
                class="state"
                :value="value"
                @click="searchByParams(value, $event, 'state')"
              />
              {{ $t("tickets." + getClassStatus(value)) }}
            </div>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--3-col mdl-cell--12-col-phone">
          <div class="contentInputFilter" style="margin-bottom: 0">
            <div class="multiselectFilter">
              {{ $t("tickets.when") }}:
              <strong
                ><span
                  :style="
                    multiSelected.period.string.length > 10
                      ? { fontSize: '16px' }
                      : { fontSize: '16px' }
                  "
                  >{{ multiSelected.period.string }}</span
                ></strong
              >
            </div>
            <div
              class="contentIcon cursorPointer"
              @click="openMultiselect('period')"
            >
              <span class="material-icons" v-if="multiSelected.period.onOff"
                >expand_less</span
              >
              <span class="material-icons" v-if="!multiSelected.period.onOff"
                >expand_more</span
              >
            </div>
          </div>
          <div class="listMultiselect" v-if="multiSelected.period.onOff">
            <div>
              <input
                type="radio"
                class="periodAll"
                name="period"
                value="-1"
                id="period--1"
                @click="searchByPeriod(-1, $t('checkCoverage.filter.all'))"
              />
              {{ $t("checkCoverage.filter.all") }}
            </div>
            <div>
              <input
                type="radio"
                class="period"
                value="14"
                name="period"
                id="period-14"
                @click="
                  searchByPeriod(14, $t('tickets.lastDays', { days: 14 }))
                "
              />
              {{ $t("tickets.lastDays", { days: 14 }) }}
            </div>
            <div>
              <input
                type="radio"
                class="period"
                value="30"
                name="period"
                id="period-30"
                @click="
                  searchByPeriod(30, $t('tickets.lastDays', { days: 30 }))
                "
              />
              {{ $t("tickets.lastDays", { days: 30 }) }}
            </div>
            <div>
              <input
                type="radio"
                class="period"
                value="90"
                name="period"
                id="period-90"
                @click="
                  searchByPeriod(90, $t('tickets.lastDays', { days: 90 }))
                "
              />
              {{ $t("tickets.lastDays", { days: 90 }) }}
            </div>
          </div>
        </div>
      </div>
      <div class="newTickets" v-if="updateView > 0" @click="getTickets()">
        {{ $t("tickets.new", updateView, { n: updateView }) }}
      </div>

      <div v-if="!IsMobile">
        <table v-if="columns.length > 0">
          <thead>
            <tr>
              <th
                v-show="header !== 'ticketNumber'"
                v-for="(header, keyHead) in this.columns"
                :key="keyHead"
              >
                {{ $t("ticketsList." + this.ticketType + "." + header) }}
              </th>
              <th class="refreshList">
                <span
                  class="material-icons"
                  @click="getTickets()"
                  v-tooltip:top.tooltip="$t('ticketsList.tooltip')"
                  >autorenew</span
                >
              </th>
            </tr>
          </thead>
          <tbody v-if="this.viewLoader">
            <tr
              v-for="(item, keyItem) in this.items"
              :key="keyItem"
              :class="{ odd: keyItem % 2 }"
            >
              <td
                v-for="(index, keyIndex) in this.columns.length"
                :key="keyIndex"
                v-show="this.columns[keyIndex] !== 'ticketNumber'"
              >
                <span
                  v-if="this.columns[keyIndex] == 'statusType'"
                  class="statusTickets"
                  :class="[
                    'status' + getClassStatus(item[this.columns[keyIndex]]),
                  ]"
                >
                  {{
                    $t(
                      "tickets." + getClassStatus(item[this.columns[keyIndex]])
                    )
                  }}
                </span>
                <span v-else-if="this.columns[keyIndex] == 'createdTime'">
                  {{ getDate(item[this.columns[keyIndex]]) }}
                </span>
                <span
                  v-else-if="
                    this.columns[keyIndex] == 'cf_from' ||
                    this.columns[keyIndex] == 'cf_to'
                  "
                >
                  {{ getDateTime(item[this.columns[keyIndex]]) }}
                </span>
                <span
                  v-else-if="
                    this.columns[keyIndex] == 'cf_servizi' &&
                    (item[this.columns[keyIndex]] == 'Consegna' ||
                      item[this.columns[keyIndex]] == 'Ritiro')
                  "
                >
                  <span v-if="item[this.columns[keyIndex]] == 'Consegna'">{{
                    $t("shipping.labelTicketShipping")
                  }}</span>
                  <span v-else>{{ $t("shipping.labelTicketPickUp") }}</span>
                </span>
                <span v-else>{{ item[this.columns[keyIndex]] }}</span>
              </td>
              <td>
                <router-link
                  :to="
                    '/' +
                    $t('nav.' + detailsUrl) +
                    '/' +
                    $t('nav.detail') +
                    '/' +
                    item.ticketNumber
                  "
                >
                  <div class="cdlButton" style="margin: 0 auto">
                    {{ $t("tickets.details") }}
                  </div>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="IsMobile && colMobile.length > 0">
        <table>
          <!--          <thead>-->
          <!--            <tr>-->
          <!--              <th-->
          <!--                style="text-align: center"-->
          <!--                v-for="(header, keyHead) in colMobile"-->
          <!--                :key="keyHead"-->
          <!--              >-->
          <!--                <span>{{-->
          <!--                  $t("ticketsList." + this.ticketType + "." + header)-->
          <!--                }}</span>-->
          <!--              </th>-->
          <!--            </tr>-->
          <!--          </thead>-->
          <tbody v-if="ticketType === 'RemoteHand'">
            <MobileCard
              class="philange"
              v-for="(item, keyItem) in this.items"
              :key="keyItem"
              :item="item"
              translation-path="ticketsList.RemoteHand"
              :titles="['createdTime', 'cf_tipo_attivita']"
            >
              <template #url-label>
                <router-link
                  :to="
                    '/' +
                    $t('nav.' + detailsUrl) +
                    '/' +
                    $t('nav.detail') +
                    '/' +
                    item.ticketNumber
                  "
                >
                  <div class="cdlButton">
                    {{ $t("tickets.details") }}
                  </div>
                </router-link></template
              >
            </MobileCard>
          </tbody>
          <tbody v-if="ticketType === 'Ingresso'">
            <MobileCard
              class="philange"
              v-for="(item, keyItem) in this.items"
              :key="keyItem"
              :item="item"
              translation-path="ticketsList.Ingresso"
              :titles="['cf_from', 'cf_to']"
            >
              <template #url-label>
                <router-link
                  :to="
                    '/' +
                    $t('nav.' + detailsUrl) +
                    '/' +
                    $t('nav.detail') +
                    '/' +
                    item.ticketNumber
                  "
                >
                  <div class="cdlButton">
                    {{ $t("tickets.details") }}
                  </div>
                </router-link></template
              >
            </MobileCard>
          </tbody>
          <tbody v-if="ticketType === 'Shipping'">
            <MobileCard
              class="philange"
              v-for="(item, keyItem) in this.items"
              :key="keyItem"
              :item="item"
              translation-path="ticketsList.Shipping"
              :titles="['cf_data_arrivo_pacco', 'cf_servizi']"
            >
              <template #url-label>
                <router-link
                  :to="
                    '/' +
                    $t('nav.' + detailsUrl) +
                    '/' +
                    $t('nav.detail') +
                    '/' +
                    item.ticketNumber
                  "
                >
                  <div class="cdlButton">
                    {{ $t("tickets.details") }}
                  </div>
                </router-link></template
              >
            </MobileCard>
          </tbody>
          <!-- <tr>
              <td v-for="(header, keyHead) in colMobile" :key="keyHead">
                <span
                  v-if="
                    header == 'createdTime' ||
                    header == 'cf_data_arrivo_pacco' ||
                    header == 'cf_from' ||
                    header == 'cf_to'
                  "
                  >{{ getDateTime(item[this.columns[header]]) }}</span
                >
                <span v-else>{{ item[header] }}</span>
              </td>
            </tr>
            <tr>
              <td :colspan="colMobile.length">
                <router-link
                  :to="
                    '/' +
                    $t('nav.' + detailsUrl) +
                    '/' +
                    $t('nav.detail') +
                    '/' +
                    item.ticketNumber
                  "
                >
                  <div class="cdlButton" style="margin: 0 auto">
                    {{ $t("tickets.details") }}
                  </div>
                  <hr />
                </router-link>
              </td>
            </tr> -->
        </table>
      </div>

      <div class="noTickets" v-if="columns.length == 0 && viewLoader">
        {{ $t("tickets.noTickets") }}
      </div>
      <div>
        <br /><br />
        <MyLoading :viewLoader="this.viewLoader" type="" />
        <MyError :viewError="viewError" :message="$t('tickets.error')" />
      </div>
      <br /><br />
      <Paginator
        v-show="total_page > 1"
        :total-page="total_page"
        :current-page="current_page"
        @change-page="changePage"
        @prev-page="beforePage"
        @next-page="nextPage"
      />
      <br /><br />
      <!-- <div class="contentPageTickets" v-if="total_page > 1">
        <div
          @click="beforePage()"
          class="arrow"
          :class="{ disabled: current_page == 1 }"
        >
          <span class="material-icons"> chevron_left </span>
        </div>
        <div
          v-for="page in total_page"
          :key="page"
          @click="changePage(page)"
          :class="{ current: current_page == page, hide: getCtrlPage(page) }"
          class="ctrlPoints"
        >
          <span
            class="hide"
            :id="'page-' + page"
            :class="{
              viewPoints: (total_page > 10 && page != 4) || total_page <= 10,
            }"
            >{{ page }}</span
          >
          <span
            class="hide"
            :class="{ viewPoints: total_page > 10 && page == 4 }"
            @click="viewAllPage()"
            >...</span
          >
        </div>
        <div
          @click="nextPage()"
          class="arrow"
          :class="{ disabled: current_page == total_page }"
        >
          <span class="material-icons"> chevron_right </span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import MyLoading from "@/components/MyLoading.vue";
import MyError from "@/components/MyError.vue";
import Paginator from "@/components/Paginator.vue";

import "@/assets/scss/TicketsList.scss";
import MobileCard from "@/components/Cards/MobileCard.vue";

export default {
  name: "TicketsList",
  props: ["ticketType", "detailsUrl"],
  components: {
    MobileCard,
    MyLoading,
    MyError,
    Paginator,
  },
  data() {
    return {
      columns: [],
      items: [],
      searchString: "",
      multiSelected: {
        state: {
          onOff: false,
          string: this.$t("tickets.all"),
          values: [],
          items: ["Open", "On Hold", "Closed"],
        },
        period: {
          onOff: false,
          string: this.$t("tickets.all"),
          values: [],
          items: [],
        },
        // user_refs: {
        //   onOff: false,
        //   string: "Tutto",
        //   values: [],
        //   items: []
        // }
      },
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
        "Content-Type": "multipart/form-data",
      },
      page_size: 10,
      total_page: 1,
      current_page: 1,
      viewLoader: true,
      viewError: false,
      messageError: "",
      filterDate: "",
      IsMobile: this.$IsMobile(),
      totalTicket: 0,
      updateView: 0,
      ctrlNewTickets: {},
      colMobile: [],
    };
  },
  methods: {
    searchByString() {
      if (this.searchString.length >= 3 || this.searchString.length == 0) {
        this.getTickets();
      }
    },
    searchByParams(params, ev, classSel) {
      let pointer = this.multiSelected.state;

      // switch( classSel ) {
      //   case 'user_refs':
      //     pointer = this.multiSelected.user_refs;
      //     break;

      // }

      (function (x, params, ev, labelAll) {
        if (params == "Tutto") {
          x.values = [];
          x.string = labelAll;
          let checkBox = document.getElementsByClassName(classSel);
          for (let i = 0; i < checkBox.length; i++) {
            checkBox[i].checked = false;
          }
        } else {
          let classAll = classSel + "All";
          document.getElementsByClassName(classAll)[0].checked = false;
          if (ev.target.checked) {
            x.values.push(params);
          } else {
            let index = x.values.indexOf(params);
            if (index !== -1) {
              x.values.splice(index, 1);
            }
          }

          x.string = x.values;
        }
      })(pointer, params, ev, this.$t("tickets.all"));

      if (pointer.values.length == 0) {
        pointer.string = this.$t("tickets.all");
        let checkBox = document.getElementsByClassName(classSel);
        for (let i = 0; i < checkBox.length; i++) {
          checkBox[i].checked = false;
        }
        document.getElementsByClassName(classSel + "All")[0].checked = true;
      }

      pointer.onOff = false;

      this.getTickets();
    },
    searchByPeriod(params, label) {
      if (params == -1) {
        this.filterDate = "";
      } else {
        // yyyy-MM-ddThh:mm:ss.SSSZ
        let dateTo = moment().format("DD-MM-YYYY HH:mm:ss") + ".000Z";
        let dateFrom =
          moment().subtract(params, "d").format("DD-MM-YYYY T HH:mm:ss") +
          ".000Z";

        this.filterDate = dateFrom + "," + dateTo;
      }

      this.multiSelected.period.string = label;

      this.multiSelected.period.values.push(params);

      this.multiSelected.period.onOff = false;

      document.getElementById("period-" + params).checked = true;

      this.getTickets();
    },
    openMultiselect(type) {
      let ctrl = true;
      let selected = [];
      let pointer = "";
      switch (type) {
        case "state":
          pointer = this.multiSelected.state;
          break;
        case "period":
          pointer = this.multiSelected.period;
          break;
        // case 'user_refs':
        //   pointer = this.multiSelected.user_refs;
        //   break;
      }

      (function (x) {
        x.onOff = !x.onOff;
        ctrl = x.onOff;
        selected = x.values;
      })(pointer);

      if (ctrl && selected.length > 0) {
        let checkBox = document.getElementsByClassName(type);
        setTimeout(() => {
          for (let i = 0; i < checkBox.length; i++) {
            checkBox[i].checked = false;
            selected.forEach((elm) => {
              if (checkBox[i].value == elm) {
                checkBox[i].checked = true;
              }
            });
          }
        }, 100);
      } else if (ctrl && selected.length == 0) {
        let classAll = type + "All";
        setTimeout(() => {
          document.getElementsByClassName(classAll)[0].checked = true;
        }, 100);
      }
    },
    getDate(myTime) {
      return moment(myTime).format("DD-MM-YYYY", this.$i18n.locale, true);
    },
    getDateTime(myTime) {
      return moment(myTime).format("DD-MM-YYYY HH:mm", this.$i18n.locale, true);
    },
    getClassStatus(string) {
      return typeof string == "string" ? string.replace(" ", "") : "";
    },
    getTickets() {
      this.viewLoader = false;

      this.totalTicket = 0;
      this.updateView = 0;
      this.total_page = 0;

      let params = {
        page_size: this.page_size,
        service: this.ticketType,
        page: this.current_page,
      };

      if (this.searchString != "") {
        params.search_by = this.searchString;
      }

      if (this.multiSelected.state.values.length > 0) {
        params.status = this.multiSelected.state.values.join(",");
      }

      // if (this.multiSelected.user_refs.values.length > 0) {
      //   params.user_refs = this.multiSelected.user_refs.values.join(',');
      // }

      if (this.filterDate != "") {
        params.createdTimeRange = this.filterDate;
      }

      const self = this;

      this.viewError = false;

      this.items = [];

      this.axios
        .post(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "tickets/v1/ticket-list",
          params,
          { headers: this.he }
        )
        .then((response) => {
          this.columns = response.data.headers;
          this.items = response.data.data;

          this.total_page = Math.ceil(response.data.count / this.page_size);

          if (this.total_page < 1) {
            this.total_page = 1;
          }

          this.viewLoader = true;

          // this.ctrlNewTickets = setInterval(() => {
          //   let timestamp = new Date().valueOf();
          //   let he = {
          //     Accept: "application/json",
          //     Authorization: "Bearer " + this.$keycloak.idToken,
          //   };
          //   this.axios
          //     .get(
          //       this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
          //         "tickets/v1/tickets-total?service=" +
          //         this.ticketType +
          //         "&timestamp=" +
          //         timestamp,
          //       { headers: he }
          //     )
          //     .then((response) => {
          //       if (this.totalTicket == 0) {
          //         this.totalTicket = response.data.count;
          //       }
          //       if (this.totalTicket != response.data.count) {
          //         this.updateView = response.data.count - this.totalTicket;
          //       }
          //     })
          //     .catch(function (err) {
          //       console.log(err);
          //     });
          // }, 5000);
        })
        .catch(function (err) {
          console.log(err);
          if (err.request.status == 404) {
            self.viewLoader = true;
            return;
          }
          self.viewLoader = true;
          self.viewError = true; //err.message; //"Nessuna copertura";
          self.messageError = err.message;
          setTimeout(() => {
            self.viewError = false;
          }, 5000);
        });
    },
    changePage(page) {
      this.current_page = parseInt(page);
      this.getTickets();
    },
    nextPage() {
      if (this.current_page == this.total_page) {
        return;
      }
      this.current_page = parseInt(this.current_page) + 1;
      this.getTickets();
    },
    beforePage() {
      if (this.current_page == 1) {
        return;
      }
      this.current_page = parseInt(this.current_page) - 1;
      this.getTickets();
    },
    initMobile() {
      switch (this.ticketType) {
        case "RemoteHand":
          this.colMobile = ["createdTime", "cf_tipo_attivita"];
          break;
        case "Shipping":
          this.colMobile = ["cf_data_arrivo_pacco", "cf_servizi"];
          break;
        case "Ingresso":
          this.colMobile = ["cf_from", "cf_to"];
          break;
      }
    },
    getLangFilter() {
      if (
        this.multiSelected.state.string == "Tutto" ||
        this.multiSelected.state.string == "All"
      ) {
        this.multiSelected.state.string = this.$t("tickets.all");
      }
      if (
        this.multiSelected.period.string == "Tutto" ||
        this.multiSelected.period.string == "All"
      ) {
        this.multiSelected.period.string = this.$t("tickets.all");
      }
    },
  },
  updated() {
    this.getLangFilter();
  },
  unmounted() {
    // clearInterval(this.ctrlNewTickets);
  },
  mounted() {
    window.scrollTo(0, 0);

    this.getTickets();

    if (this.IsMobile) {
      this.initMobile();
    }
  },
};
</script>
