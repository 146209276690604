<template>
  <div id="Solutions">
    <MyHeaderPage :title="$t('cloud.title')" />
    <br /><br />
    <div id="LinesList" v-if="!this.noItems && !this.noTicketItems">
      <div class="listLines">
        <div class="mdl-grid" v-if="tab_index === 1">
          <div class="mdl-cell mdl-cell--4-col">
            <TextSearchInput
              @search="searchByString"
              @reset="resetSearchByString"
              :filterKey="'id'"
              :columns="['solution', 'solution_type']"
            />
          </div>
          <div class="mdl-cell mdl-cell--2-col"></div>
          <div
            class="mdl-cell mdl-cell--3-col mdl-cell--12-col-phone"
            v-show="
              ctrlPerms(['incident_lista', 'change_request_list']) &&
              tab_index !== 2
            "
          >
            <div class="helpText alignRight">
              <p class="textXL">{{ $t("help.generic.btnLabel") }}</p>
            </div>
          </div>
          <HelpRequestBtn
            v-if="ctrlPerms(['incident_lista']) && tab_index !== 2"
            :component-name="'CloudGenericIncidentForm'"
            :asset-type="'cloud'"
            :icon="'warning'"
            :label="$t('help.incident.formTitle')"
            :element-class="'mdl-cell mdl-cell--1-col'"
          />
          <HelpRequestBtn
            v-if="ctrlPerms(['change_request_list']) && tab_index !== 2"
            :component-name="'CloudGenericChangeRequestForm'"
            :asset-type="'cloud'"
            :icon="'person'"
            :label="$t('help.changeRequest.formTitle')"
            :element-class="'mdl-cell mdl-cell--1-col'"
          />
        </div>
        <div class="mdl-grid" v-else>
          <div class="mdl-cell mdl-cell--4-col">
            <TextSearchInput
              :disabled="!viewLoader"
              @search="searchByString"
              @reset="resetSearchByString"
              :filterKey="'id'"
              :columns="['solution', 'solution_type']"
            />
          </div>
        </div>

        <TabContainer>
          <template #tabs>
            <Tab
              id="tabOne"
              :label="$t('tabs.listLabel')"
              @click="setTab(1)"
              :class="{ 'is-active': tab_index === 1 }"
            ></Tab>
            <Tab
              v-if="ctrlPerms(['incident_lista', 'change_request_list'])"
              id="tabTwo"
              :label="$t('tabs.ticketListLabel')"
              @click="setTab(2)"
              :class="{ 'is-active': tab_index === 2 }"
            ></Tab>
          </template>
          <template #content>
            <div
              class="mdl-tabs__panel"
              :class="{ 'is-active': tab_index === 1 }"
              v-show="tab_index === 1"
            >
              <div class="mdl-grid">
                <div class="mdl-cell mdl-cell--4-col">
                  <span v-if="items" class="textInfo">{{
                    $t("colocation.itemCount", { total_count: total_items })
                  }}</span>
                </div>
              </div>
              <!-- LISTA -->
              <div id="CloudList">
                <table>
                  <thead v-if="!IsMobile">
                    <tr>
                      <th v-if="!IsMobile">{{ $t("cloud.assetType") }}</th>
                      <th>{{ $t("cloud.solution") }}</th>
                      <th class="alignCenter">{{ $t("cloud.tbyte") }}</th>
                      <th class="alignCenter">RAM (MB)</th>
                      <th class="alignCenter">vCPU</th>
                      <th v-if="!IsMobile"></th>
                      <th v-if="!IsMobile"></th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="
                      IsMobile &&
                      this.viewLoader &&
                      this.items &&
                      this.items.length
                    "
                  >
                    <MobileCard
                      v-for="(item, keyItem) in this.filtered"
                      :key="keyItem"
                      :titles="['solution_type', 'solution']"
                      :item="item"
                      translation-path="ticketsList.Cloud"
                    >
                    </MobileCard>
                  </tbody>
                  <tbody
                    v-else-if="
                      !IsMobile &&
                      this.viewLoader &&
                      this.filtered &&
                      this.filtered.length
                    "
                  >
                    <tr
                      v-for="(item, keyItem) in this.filtered"
                      :key="keyItem"
                      :class="{ odd: keyItem % 2 }"
                    >
                      <td>
                        <span>{{
                          $t("cloud.types." + item.solution_type)
                        }}</span>
                      </td>
                      <td>
                        <span>{{ item.solution }}</span>
                      </td>
                      <td class="alignCenter">
                        <span>{{ item.size }}</span>
                      </td>
                      <td class="alignCenter">
                        <span>{{ item.ram }}</span>
                      </td>
                      <td class="alignCenter">
                        <span>{{ item.vcpu }}</span>
                      </td>
                      <td>
                        <HelpRequestBtn
                          v-if="ctrlPerms(['incident_lista'])"
                          :component-name="'CloudIncidentForm'"
                          :asset-type="'cloud'"
                          :item-id="item.id"
                          :icon="'warning'"
                          :label="$t('help.incident.formTitle')"
                        />
                      </td>
                      <td>
                        <HelpRequestBtn
                          v-if="ctrlPerms(['change_request_list'])"
                          :component-name="'CloudChangeRequestForm'"
                          :asset-type="'cloud'"
                          :item-id="item.id"
                          :icon="'person'"
                          :label="$t('help.changeRequest.formTitle')"
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="this.viewLoader && !this.items.length">
                    <tr>
                      <td colspan="7" style="text-align: center">
                        <span v-if="this.viewError" class="error-message">{{
                          $t("cloud.error")
                        }}</span>
                        <span>{{
                          noItems ? $t("colocation.noData") : $t("cloud.TBD")
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <MyError
                    :viewError="viewError"
                    :message="$t('tickets.error')"
                  />
                </div>
                <Paginator
                  v-show="total_page > 1 && viewLoader"
                  :total-page="total_page"
                  :current-page="current_page"
                  @change-page="changePage"
                  @prev-page="prevPage"
                  @next-page="nextPage"
                />
                <div class="mdl-grid" v-show="!viewLoader">
                  <div
                    class="mdl-cell mdl-cell--12-col"
                    style="text-align: center"
                  >
                    <MyLoading :viewLoader="viewLoader" type="" />
                  </div>
                </div>
              </div>
              <!-- FINE LISTA -->
            </div>
            <div
              class="mdl-tabs__panel"
              :class="{ 'is-active': tab_index === 2 }"
              v-show="tab_index === 2"
            >
              <ColocationTicketList
                :list="filtered"
                :view-loader="viewLoader"
                :view-error="viewError"
                :total-page="total_page"
                :total-count="total_count"
                :component-name-incident="'CloudIncidentDetail'"
                :component-name-change-request="'CloudChangeRequestDetail'"
                @refresh="getTicketList"
              />
              <div class="mdl-grid" v-show="!viewLoader">
                <div
                  class="mdl-cell mdl-cell--12-col"
                  style="text-align: center"
                >
                  <MyLoading :viewLoader="viewLoader" type="" />
                </div>
              </div>
            </div>
          </template>
        </TabContainer>
      </div>
    </div>
    <div v-else>
      <CNoSolutionsService
        :text="'noSolutionsServices.cloud'"
        :viewBtn="false"
      />
    </div>
  </div>
</template>

<style lang="scss">
td,
th {
  text-align: left;
  &.alignCenter {
    text-align: center;
  }
}
</style>

<script>
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyLoading from "@/components/MyLoading.vue";
import ColocationTicketList from "@/components/Colocation/TicketList.vue";
import TextSearchInput from "@/components/Search/TextSearch/TextSearchInput.vue";
import HelpRequestBtn from "@/components/Tables/HelpRequestBtn";
import TabContainer from "@/components/Utils/TabContainer.vue";
import Tab from "@/components/Utils/Tab.vue";
import {filterDataByColumns} from "@/services/SearchService";
import CNoSolutionsService from "@/components/NoSolutionsService.vue";
import {getCloudItemList} from "@/services/AssetService.js";
import MyError from "@/components/MyError.vue";
import Paginator from "@/components/Paginator.vue";
import MobileCard from "@/components/Cards/MobileCard.vue";

export default {
  name: "CCloud",
  components: {
    MobileCard,
    MyHeaderPage,
    MyLoading,
    TextSearchInput,
    HelpRequestBtn,
    TabContainer,
    Tab,
    ColocationTicketList,
    CNoSolutionsService,
    MyError,
    Paginator,
  },
  data() {
    return {
      items: [],
      filtered: [],
      search_columns: ["solution", "solution_type"],
      tab_index: 1,
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      viewLoader: true,
      viewError: false,
      noItems: false,
      noTicketItems: false,
      links: [],
      current_page: 1,
      page_size: 10,
      total_page: 1,
      total_count: 0,
      search_by_string_key: "",
      IsMobile: this.$IsMobile(),
    };
  },
  methods: {
    searchByString(keyword) {
      this.search_by_string_key = keyword;

      this.filtered = filterDataByColumns(
        this.items,
        this.search_columns,
        this.search_by_string_key
      );

      // console.log(`${this.filtered.length} items found for ${keyword}`);

      // if no results are found in cache refresh data with search params
      if (!this.filtered.length) {
        this.filtered = [];
      }

      this.total_count = this.items.length;
    },
    resetSearchByString() {
      this.search_by_string_key = "";
      if (this.tab_index === 1) {
        this.getList();
      } else {
        this.getTicketList();
      }
    },
    setTab(index) {
      this.total_items = 0;
      this.tab_index = index;
      this.search_by_string_key = "";
      if (index === 1) {
        this.search_columns = ["solution", "solution_type"];
        this.getList();
      } else {
        this.search_columns = ["subject", "cf_sold_solution", "cf_short_title"];
        this.getTicketList();
      }
    },
    getList(page = null) {
      this.filtered = [];
      this.items = [];
      this.viewLoader = false;

      let self = this;

      getCloudItemList(this.he, page)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            // console.log("response.data.data", response.data.data);
            this.items = response.data.data;
            this.filtered = response.data.data;
            this.links = response.data.links;
            this.total_items = response.data.total;
            this.current_page = response.data.current_page;
            this.total_page = response.data.last_page;
            this.noItems = false;
          } else {
            this.noItems = true;
          }
          this.viewLoader = true;
        })
        .catch(function (err) {
          //TODO: FIX THIS IN BE
          // manage 404 code if no tickets are found
          if (err.response.status === 404) {
            console.log("NOT FOUND");
            self.viewError = true;
            self.viewLoader = true;
            self.items = [];
            self.filtered = [];
            return;
          }
          self.viewLoader = true;
          self.viewError = true;
        });
    },
    getTicketList() {
      const URL = "tickets/v1/ticket-list";
      const TICKET_TYPE = "Incident,ChangeRequest";

      let params = {
        page_size: this.page_size,
        service: TICKET_TYPE,
        page: this.current_page,
        asset_type: "cloud",
      };

      this.viewLoader = false;
      this.total_count = 0;
      const self = this;

      if (this.search_by_string_key.length > 3) {
        params = {
          ...params,
          search_by: this.search_by_string_key,
          filter_by: this.sear,
        };
      }

      this.axios
        .post(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + URL, params, {
          headers: this.he,
        })
        .then((response) => {
          if (response.data.data.length > 0) {
            self.items = response.data.data;
            self.filtered = response.data.data;
            self.total_count = response.data.count;
            self.viewLoader = true;
            this.noTicketItems = false;
          } else {
            self.viewLoader = true;
            this.noTicketItems = true;
          }
        })
        .catch(function (err) {
          //TODO: FIX THIS IN BE
          // manage 404 code if no tickets are found
          if (err.response.status === 404) {
            console.log("NOT FOUND");
            self.viewLoader = true;
            self.items = [];
            self.filtered = [];
            return;
          }
          self.viewLoader = true;
          self.viewError = true;
          self.messageError = err.message;
          setTimeout(() => {
            self.viewError = false;
          }, 5000);
        });
    },
    changePage(page) {
      this.getList(page);
    },
    prevPage() {
      this.getList(this.current_page - 1);
    },
    nextPage() {
      this.getList(this.current_page + 1);
    },
    ctrlPerms(perms) {
      return this.$ctrlPermissions(perms);
    },
  },

  mounted() {
    window.scrollTo(0, 0);
    this.$IsLoggedIn();
    this.getList();
  },
};
</script>
