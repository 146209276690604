<template>
  <HelpRequestConfirmed
    :confirmedRequestId="this.incidentId"
    v-if="this.incidentId"
  >
    <template #tail>
      <div class="contentButton">
        <div
          class="cdlButtonGreen"
          style="width: auto"
          @click="
            this.$router.push({
              name: this.backToListUrl,
            })
          "
        >
          {{ $t("colocation.backToList") }}
        </div>
      </div>
    </template>
  </HelpRequestConfirmed>

  <div id="incidentForm" v-else>
    <div class="contentFormTwoCol" v-if="!isHub">
      <HelpRequestGeneric :help-request-type="'generic.incident'" />
      <div class="contentSecondary">
        <h2 class="title">{{ $t("help.incident.formTitle") }}</h2>
        <div class="mdl-grid padding0">
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelTwo">{{ $t("help.mainInfo") }}</span>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <div class="mdl-cell mdl-cell--12-col">
                <span class="labelTwo">{{ $t("help.title") }}*</span>
              </div>
              <input
                type="text"
                maxlength="100"
                name="title"
                v-model="fields.title"
                @input="label($event)"
              />
              <span
                class="error"
                v-if="
                  this.v$.fields.title.$invalid &&
                  this.v$.fields.title.$dirty &&
                  this.v$.fields.title.required.$invalid
                "
              >
                {{ $t("help.title") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div
            class="mdl-cell mdl-cell--6-col"
            v-show="
              this.assetTypeFromParams === 'colocation' ||
              this.assetTypeFromParams === 'cloud'
            "
          >
            <div class="mdl-cell mdl-cell--12-col">
              <span class="labelTwo">{{ $t("help.incident.category") }}*</span>
            </div>
            <div class="cdl-input">
              <select
                name="category"
                @input="label($event)"
                style="margin-left: 0"
                v-model="fields.category"
              >
                <option
                  :value="category.id"
                  v-for="category in this.categories"
                  :key="category.id"
                >
                  {{ category.value }}
                </option>
              </select>
              <span
                class="error"
                v-if="
                  this.v$.fields.severity_level.$invalid &&
                  this.v$.fields.severity_level.$dirty
                "
              >
                {{ $t("help.incident.category") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col"></div>
          <div class="mdl-cell mdl-cell--12-col">
            <div class="cdl-textarea">
              <div class="mdl-cell mdl-cell--12-col">
                <span class="labelTwo">{{ $t("help.description") }}*</span>
              </div>
              <textarea
                maxlength="1000"
                name="description"
                id="textareaTwo"
                v-model="fields.description"
                @input="label($event)"
              ></textarea>
              <span
                class="error"
                v-if="
                  this.v$.fields.description.$invalid &&
                  this.v$.fields.description.$dirty &&
                  this.v$.fields.description.required.$invalid
                "
              >
                {{ $t("help.description") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
        </div>
        <hr />

        <div class="mdl-grid padding0">
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelTwo"
              >{{ $t("help.incident.severityLevel") }}*</span
            >
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <select
                name="severity_level"
                @input="label($event)"
                style="margin-left: 0"
                v-model="fields.severity_level"
              >
                <option
                  v-for="level in severityLevels"
                  :key="level"
                  :value="level"
                >
                  {{ $t("help.incident.severityLevel" + level) }}
                </option>
              </select>
              <span
                class="error"
                v-if="
                  this.v$.fields.severity_level.$invalid &&
                  this.v$.fields.severity_level.$dirty
                "
              >
                {{ $t("help.incident.severityLevel") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
        </div>

        <hr />

        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelTwo">{{ $t("help.secondContact") }}</span>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <div class="mdl-cell mdl-cell--12-col">
                <span class="labelTwo">{{
                  $t("help.secondContactLabel")
                }}</span>
              </div>
              <input
                type="email"
                name="other_email"
                v-model="fields.other_email"
                @input="label($event)"
              />
              <span
                class="error"
                v-if="
                  this.v$.fields.other_email.$invalid &&
                  this.v$.fields.other_email.$dirty
                "
              >
                {{ $t("help.secondContact") }}
                {{ $t("help.invalidEmail") }}
              </span>
            </div>
          </div>
        </div>

        <hr />

        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <p class="labelTwo" style="margin-bottom: 0">
              {{ $t("shipping.uploadAttach") }}
            </p>
            <span class="subTitleAttach">{{
              $t("shipping.uploadAttachSubTitle", {
                total: this.nAttach,
                size: getLimitSizeAttach(),
              })
            }}</span>
            <br />
            <span class="error" v-if="errorAttach">
              {{
                $t("shipping.attachFormat", { format: getFormatNameAttach() })
              }}
            </span>
            <span class="error" v-if="errorAttachSize">
              {{ $t("shipping.attachSize", { size: getLimitSizeAttach() }) }}
            </span>
            <br />
            <br />
          </div>
          <div class="mdl-cell mdl-cell--12-col listAttach">
            <div
              :class="{
                hover:
                  this.fields.attachments.length == 0 ||
                  this.fields.attachments[0],
              }"
              v-if="this.nAttach >= 1"
            >
              <img
                src="@/assets/img/shipping/free_attach.png"
                alt="attach"
                v-if="this.fields.attachments.length == 0"
              />
              <img
                src="@/assets/img/shipping/complete_attach.png"
                alt="attach"
                v-if="this.fields.attachments[0]"
              />
              <span
                class="material-icons deleteAttach"
                v-if="this.fields.attachments[0]"
                @click="removeAttach(0)"
                >cancel</span
              >
              <p v-if="this.fields.attachments[0]" class="fileName">
                {{ this.fields.attachments[0].name }}
              </p>
              <input
                type="file"
                @change="onFileChanged($event, 0)"
                :accept="getFormatAttach()"
                capture
                class="fileInput"
              />
            </div>
            <div
              :class="{
                hover: this.fields.attachments[0] || this.fields.attachments[1],
              }"
              v-if="this.nAttach >= 2"
            >
              <img
                src="@/assets/img/shipping/free_attach.png"
                alt="attach"
                v-if="this.fields.attachments[0] && !this.fields.attachments[1]"
              />
              <img
                src="@/assets/img/shipping/no_attach.png"
                alt="attach"
                v-if="
                  !this.fields.attachments[0] && !this.fields.attachments[1]
                "
              />
              <img
                src="@/assets/img/shipping/complete_attach.png"
                alt="attach"
                v-if="this.fields.attachments[1]"
              />
              <span
                class="material-icons deleteAttach"
                v-if="this.fields.attachments[1]"
                @click="removeAttach(1)"
                >cancel</span
              >
              <p v-if="this.fields.attachments[1]" class="fileName">
                {{ this.fields.attachments[1].name }}
              </p>
              <input
                type="file"
                @change="onFileChanged($event, 1)"
                :accept="getFormatAttach()"
                capture
                class="fileInput"
              />
            </div>
            <div
              :class="{
                hover: this.fields.attachments[1] || this.fields.attachments[2],
              }"
              v-if="this.nAttach >= 3"
            >
              <img
                src="@/assets/img/shipping/free_attach.png"
                alt="attach"
                v-if="this.fields.attachments[1] && !this.fields.attachments[2]"
              />
              <img
                src="@/assets/img/shipping/no_attach.png"
                alt="attach"
                v-if="
                  !this.fields.attachments[1] && !this.fields.attachments[2]
                "
              />
              <img
                src="@/assets/img/shipping/complete_attach.png"
                alt="attach"
                v-if="this.fields.attachments[2]"
              />
              <span
                class="material-icons deleteAttach"
                v-if="this.fields.attachments[2]"
                @click="removeAttach(2)"
                >cancel</span
              >
              <p v-if="this.fields.attachments[2]" class="fileName">
                {{ this.fields.attachments[2].name }}
              </p>
              <input
                type="file"
                @change="onFileChanged($event, 2)"
                :accept="getFormatAttach()"
                capture
                class="fileInput"
              />
            </div>
            <div
              :class="{
                hover: this.fields.attachments[2] || this.fields.attachments[3],
              }"
              v-if="this.nAttach >= 4"
            >
              <img
                src="@/assets/img/shipping/free_attach.png"
                alt="attach"
                v-if="this.fields.attachments[2] && !this.fields.attachments[3]"
              />
              <img
                src="@/assets/img/shipping/no_attach.png"
                alt="attach"
                v-if="
                  !this.fields.attachments[2] && !this.fields.attachments[3]
                "
              />
              <img
                src="@/assets/img/shipping/complete_attach.png"
                alt="attach"
                v-if="this.fields.attachments[3]"
              />
              <span
                class="material-icons deleteAttach"
                v-if="this.fields.attachments[3]"
                @click="removeAttach(3)"
                >cancel</span
              >
              <p v-if="this.fields.attachments[3]" class="fileName">
                {{ this.fields.attachments[3].name }}
              </p>
              <input
                type="file"
                @change="onFileChanged($event, 3)"
                :accept="getFormatAttach()"
                capture
                class="fileInput"
              />
            </div>
            <div
              :class="{
                hover: this.fields.attachments[3] || this.fields.attachments[4],
              }"
              v-if="this.nAttach >= 5"
            >
              <img
                src="@/assets/img/shipping/free_attach.png"
                alt="attach"
                v-if="this.fields.attachments[3] && !this.fields.attachments[4]"
              />
              <img
                src="@/assets/img/shipping/no_attach.png"
                alt="attach"
                v-if="
                  !this.fields.attachments[3] && !this.fields.attachments[4]
                "
              />
              <img
                src="@/assets/img/shipping/complete_attach.png"
                alt="attach"
                v-if="this.fields.attachments[4]"
              />
              <span
                class="material-icons deleteAttach"
                v-if="this.fields.attachments[4]"
                @click="removeAttach(4)"
                >cancel</span
              >
              <p v-if="this.fields.attachments[4]" class="fileName">
                {{ this.fields.attachments[4].name }}
              </p>
              <input
                type="file"
                @change="onFileChanged($event, 4)"
                :accept="getFormatAttach()"
                capture
                class="fileInput"
              />
            </div>
          </div>
        </div>

        <input
          type="hidden"
          ref="hiddenInput"
          :value="$t(getNameSolution())"
        />
        <hr />

        <div class="cdlContentButtonSingle">
          <div
            class="cdlButtonSingle green"
            :style="this.disableSubmit ? ['opacity: .5'] : ''"
            @click="submit()"
          >
            {{ $t("help.submitLabel") }}
          </div>
        </div>

        <div class="deleteOrder">
          <a style="color: black" @click="this.$router.go(-1)">
            {{ $t("remoteHands.deleteTicket") }}
          </a>
        </div>
      </div>
    </div>
    <!-- HUBSPOT -->
    <div class="contentFormTwoCol" v-else>
      <HelpRequestGeneric :help-request-type="'generic.incident'" />
      <div class="contentSecondary">
        <h2 class="title">{{ $t("help.incident.formTitle") }}</h2>
        <div class="mdl-grid padding0">
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelTwo">{{ $t("help.mainInfo") }}</span>
          </div>
        </div>
        <MyLoading :viewLoader="viewLoader" type="" />
        <div class="content-hub" :style="{ opacity: opacityForm }">
          <div :id="formId" v-once></div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { email, requiredIf } from "@vuelidate/validators";
import HelpRequestConfirmed from "@/components/Help/HelpRequestConfirmed.vue";
import HelpRequestGeneric from "@/components/Help/HelpRequestGeneric.vue";
import MyLoading from "@/components/MyLoading.vue";

import { ref } from "vue";
import {
  getBackUrlFromAssetType,
  getIncidentSeverityLevels,
} from "@/services/TicketService.js";
import "@/assets/scss/Global.scss";
import "@/assets/scss/Help.scss";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "GenericIncidentForm",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HelpRequestConfirmed,
    HelpRequestGeneric,
    MyLoading
  },
  validations() {
    return {
      fields: {
        severity_level: {
          requiredIfFoo: requiredIf(this.isHub),
        },
        title: {
          requiredIfFoo: requiredIf(this.isHub),
        },
        description: {
          requiredIfFoo: requiredIf(this.isHub),
        },
        other_email: { email },
      },
    };
  },
  data() {
    return {
      incidentId: null,
      assetTypeFromParams: null,
      disableSubmit: false,
      nAttach: 5,
      errorAttach: false,
      sizeAttach: 0,
      errorAttachSize: false,
      error: "",
      categories: [],
      solution: {},
      severityLevels: [],
      fields: {
        title: null,
        description: null,
        category: null,
        severity_severity_level: "",
        other_email: "",
        asset: 0,
        solution: ref("hidden-input").value,
        attachments: [],
      },
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
        "Content-Type": "multipart/form-data",
      },
      backToListUrl: "",
      isHub: true,
      formId: 'hubspotForm',
      asset_type: 'lines',
      team_link: 'incident_TLC',
      viewLoader: false,
      opacityForm: 0,
      solutionGen: ''
    };
  },
  methods: {
    label(ev) {
      this.$LabelInput(ev);
    },
    async submit() {
      //avoid multiple click
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return false;

      const CREATE_INCIDENT_URL = "tickets/v1/incident";
      this.disableSubmit = true;
      this.axios
        .post(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + CREATE_INCIDENT_URL,
          { ...this.fields, asset_type: this.assetTypeFromParams },
          { headers: this.he }
        )
        .then((res) => {
          if (res.status !== 200) {
            window.scrollTo(0, 0);
            this.error = res.status + ": " + res.data;
            return;
          }
          //go to ticket detail
          this.incidentId = res.data;
        })
        .then(() => {
          this.disableSubmit = false;
        })
        .catch((err) => {
          console.error(err);
          this.error = err.response.data.message;
        });
    },
    getCategories() {
      if (
        this.assetTypeFromParams !== "colocation" &&
        this.assetTypeFromParams !== "cloud"
      )
        return;

      let setLines = "";
      if (this.assetTypeFromParams == "colocation") {
        setLines = ",lines";
      }

      const GET_CATEGORIES_URL =
        "front/v1/incident-categories?service=" +
        this.assetTypeFromParams +
        setLines;

      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + GET_CATEGORIES_URL,

          { headers: this.he }
        )
        .then((res) => {
          if (res.status !== 200) {
            window.scrollTo(0, 0);
            this.error = res.status + ": " + res.data;
            return;
          }
          //go to ticket detail
          this.categories = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.error = err.response.data.message;
        });
    },
    onFileChanged($event, index) {
      const target = $event.target;
      if (target && target.files) {
        if (
          this.getFormatAttach().search(target.files[0].type) < 0 ||
          target.files[0].type == ""
        ) {
          this.errorAttach = true;
          return;
        }

        this.errorAttach = false;
        this.errorAttachSize = false;

        this.sizeAttach += parseFloat(this.getSize(target.files[0].size));

        if (this.sizeAttach > this.getLimitSizeAttach()) {
          this.errorAttachSize = true;
          return;
        }

        this.fields.attachments[index] = target.files[0];
      }
    },
    getSeverityLevels() {
      getIncidentSeverityLevels(this.he).then((res) => {
        this.severityLevels = res.data;
      });
    },
    removeAttach(index) {
      this.fields.attachments.splice(index, 1);
    },
    getSize(sizeInBytes) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2);
    },
    getLimitSizeAttach() {
      return window.VUE_APP_LIMIT_SIZE_ATTACH;
    },
    getFormatAttach() {
      return window.VUE_APP_TYPE_ATTACH;
    },
    getFormatNameAttach() {
      return window.VUE_APP_TYPE_ATTACH_NAME.replaceAll("&nbsp;", " ");
    },
    getNameSolution() {
      return this.$route.params.asset_type == 'cross-connects' ? 'help.generic.incident.cross_connects.solution' : 'help.generic.incident.solution'
    },
    hub() {
      if( document.getElementById(this.formId) ) {
        if( document.getElementById(this.formId).getElementsByTagName("iframe")[0] ) {
          document.getElementById(this.formId).getElementsByTagName("iframe")[0].remove();
        }

        let infoAccount = useUserStore().user.account;
        let infoUser = useUserStore().user;

        this.loadForm().then(() => {
          setTimeout(() => {
            let iFrame = document.getElementById(this.formId).getElementsByTagName("iframe")[0];
            let theme = '<link type="text/css" rel="stylesheet" href="../../css/hubspot.css">';
            iFrame.contentWindow.document.head.innerHTML += theme;    
            let form = iFrame.contentWindow.document.body.getElementsByTagName("form")[0];
            if( this.$route.params.asset_type != 'colocation' ) {
              let categoria_incident = form.getElementsByClassName('hs_TICKET.categoria_incident')[0]; 
              categoria_incident.style.display = "none";
            }
            let intervalForm = setInterval(() => {
              if( form ) {

                if( this.$route.params.asset_type != 'colocation' ) {
                  let categoria_incident = form.getElementsByClassName('hs_TICKET.categoria_incident')[0]; 
                  categoria_incident.style.display = "none";
                }

                let numero_azienda = form.querySelector('input[name="TICKET.codice_azienda_alyante"]'); 
                numero_azienda.value = infoAccount.id;
                numero_azienda.dispatchEvent(new Event('input', { bubbles: true }));
                numero_azienda.dispatchEvent(new Event('change', { bubbles: true }));

                let nome_azienda = form.querySelector('input[name="TICKET.azienda_provenienza_ticket"]'); 
                nome_azienda.value = infoAccount.business_name;
                nome_azienda.dispatchEvent(new Event('input', { bubbles: true }));
                nome_azienda.dispatchEvent(new Event('change', { bubbles: true }));

                let asset_type = form.querySelector('input[name="TICKET.tipologia_asset"]'); 

                let assetType = this.asset_type;
                if( assetType == 'lines' ) {
                  assetType = 'accesso';
                }

                if( assetType == 'cross-connects' ) {
                  assetType = 'colocation';
                }

                if( this.asset_type == 'voip' ) {
                  assetType = 'VOIP';
                }

                asset_type.value = assetType;
                asset_type.dispatchEvent(new Event('input', { bubbles: true }));
                asset_type.dispatchEvent(new Event('change', { bubbles: true }));

                let servizio_link_team = form.querySelector('input[name="TICKET.servizio_link_team"]'); 
                servizio_link_team.value = this.team_link;
                servizio_link_team.dispatchEvent(new Event('input', { bubbles: true }));
                servizio_link_team.dispatchEvent(new Event('change', { bubbles: true }));

                let email = form.querySelector('input[name="email"]'); 

                email.value = infoUser.email;

                email.dispatchEvent(new Event('input', { bubbles: true }));
                email.dispatchEvent(new Event('change', { bubbles: true }));

                let soluzione_impattata = form.querySelector('input[name="TICKET.soluzione_impattata"]');
                soluzione_impattata.value = this.solutionGen;
                soluzione_impattata.dispatchEvent(new Event('input', { bubbles: true }));
                soluzione_impattata.dispatchEvent(new Event('change', { bubbles: true }));

                form.dispatchEvent(new Event('submit', { cancelable: true }));

                clearInterval(intervalForm);

                this.opacityForm = 1;
                this.viewLoader = true;
              }

            }, 500)


          }, 500);

        });
      }
    },
    loadForm() {
        let formId = this.$MyCrypto(window.VUE_APP_HB_INCIDENT_FORM_EN, 1);
        if( this.$i18n.locale == 'it' ) {
          formId = this.$MyCrypto(window.VUE_APP_HB_INCIDENT_FORM_IT, 1);
        }

        return new Promise((resolve) => {
          const script = document.createElement("script");
            script.src="https://js.hsforms.net/forms/v2.js";
            document.body.appendChild(script);
            script.addEventListener("load", () => {
              if (window.hbspt) {
                window.hbspt.forms.create({
                  region: "eu1",
                  portalId: window.VUE_APP_HB_PORTAL_ID,
                  formId: formId,
                  target: "#" + this.formId,
                  onFormSubmitted: this.comeBack
                });
                setTimeout(() => {
                  resolve(true)
                }, 800);
              }
            });
          });
    },
    comeBack() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => {
        this.$router.push({
          name: this.backToListUrl,
        })
      }, 5000);
    }
  },
  mounted() {

    // retrieve backUrl from assetType param
    this.backToListUrl = getBackUrlFromAssetType(this.$route.params.asset_type);

    if( this.isHub ) {
      this.asset_type = this.$route.params.asset_type;
      this.team_link = this.$route.meta.team_link;
      this.solutionGen = this.$t('help.generic.incident.solution');
      this.hub();
    }
    else {
      this.fields.solution = this.$refs.hiddenInput.value;
      // get assetType and asset id from route
      this.assetTypeFromParams = this.$route.params.asset_type;
      // get incident categories
      this.getCategories();
      // get incident gravity levels
      this.getSeverityLevels();
    }

  },
};
</script>
